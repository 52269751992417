// Import packages
import React, { useEffect } from "react";

// Import components
import PortPagePanel from "../components/PortPagePanel";

// Import assets
import DoverLogo from "../assets/images/portfolioItems/Dover/DoverCorpLogo.png";
import PortProjectsJSON from "../assets/images/portfolioItems/PortfolioSite/PortProjectsJSON.PNG";
import ContactFormAWS from "../assets/images/portfolioItems/PortfolioSite/ContactFormAWS.PNG";
import StayUpdatedAWS from "../assets/images/portfolioItems/PortfolioSite/StayUpdatedAWS.PNG";

/*
Sections:
  Mentor
  SpecView: Rollup
  glTF Pipeline: AWS, lambdas (servless), SQS, API gatway, orchestrator, DLQ
*/

export default function DoverPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      {/* Overview panel */}
      <PortPagePanel mode="off-white">
        <h3 style={{ marginTop: "25px" }} className="port-page-section-title">
          Dover Coporation <br />
          Projects
        </h3>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "25px",
          }}
          className="port-page-section-content-offset"
        >
          <div className="overview-details">
            <div>
              <p className="overview-details-title">Role</p>
              <p className="overview-details-content">Software Engineer</p>
            </div>
            <div>
              <p className="overview-details-title">Purpose</p>
              <p className="overview-details-content">Work</p>
            </div>
            <div>
              <p className="overview-details-title">Date</p>
              <p className="overview-details-content">Nov. 2022 - Present</p>
            </div>
          </div>
        </div>
      </PortPagePanel>
      {/* Image panel */}
      <PortPagePanel>
        <img
          className="image-panel-img"
          src={DoverLogo}
          alt="Dover Corporation Logo"
        ></img>
      </PortPagePanel>
      {/* glTF panel */}
      <PortPagePanel mode="off-white">
        <h3 className="port-page-section-title">glTF Pipeline</h3>
        <div className="port-page-section-content-offset">
          <p>
            Many products in Dover's product line rely on{" "}
            <a href="https://github.com/KhronosGroup/glTF">glTFs</a>, a 3D asset
            transmission format. To create scalable conversion, compression, and
            decoration logic, I architected and created the glTF Pipeline.
            <br /> <br />
            The system was created on AWS, but the concepts apply to any
            serverless platform. API Requests are sent through an API Gateway,
            which checks authorization and passses the request to a Lambda,
            called the glTF Orchestrator.
            <br /> <br />
            The glTF Orchestrator processes the API request body, which
            specifies an operation to perform and a passes the payload to a
            respective SQS queue, attached to the particular Lambda. Each Lambda
            performs operations based on the payload passed, then passes the
            result to the required end position, typically an S3 bucket.
            <br /> <br />
            Each processing Lambda is also wrapped in an SQS Dead Letter Queue
            (DLQ). If a particular process fails, the Lambda passes the message
            to the DLQ to be manually reviewed for issues in the payload,
            process, or corresponding data.
          </p>
        </div>
      </PortPagePanel>
      {/* SpecView panel */}
      <PortPagePanel>
        <h3 className="port-page-section-title">SpecView</h3>
        <div className="port-page-section-content-offset">
          <p>
            <a href="https://platform-uat.cdsvisual.net/">SpecView</a> is a glTF
            model viewer{" "}
            <a href="https://developer.mozilla.org/en-US/docs/Web/API/Web_components">
              Web Component
            </a>
            . The product allows users to upload 3D assets and configure the
            viewer's features to fit their exact needs such as enabling standard
            features in the glTF format, such as animations, while providing
            extended functionality including measurements, annotations, view
            manipulations, and more.
            <br /> <br />
            This product is built on top of an internal tool, the Visualizer,
            which generalizes viewing 3D models. The Visualizer is created in
            React, utililzing <a href="https://threejs.org/">
              ThreeJs
            </a> and{" "}
            <a href="https://github.com/pmndrs/react-three-fiber">R3F</a>,
            amongst other 3D libraries, and bundled into both a Web Component
            and an NPM library using RollUp.
            <br />
            <br />
            These artifacts are then published to AWS Code Artifact and either
            installed in other products through an internal NPM store, or
            provided to end users in a framework-agnostic format throught the
            Web Component.
          </p>
        </div>
      </PortPagePanel>
      {/* Mentor panel */}
      <PortPagePanel mode="off-white">
        <h3 className="port-page-section-title">Mentor</h3>
        <div className="port-page-section-content-offset">
          <p>
            <a href="https://cdsvisual.com/digital-work-instructions/">
              Mentor
            </a>{" "}
            is a 3D Learning Management System (LMS) which implements the
            aforementioned Visualizer tool. An admin can decorate a particular
            glTF with animations associated to steps, annotations, lighting, and
            material manipulations including textures.
            <br /> <br />
            Once decorated, end users consume the models as a training data to
            learn assembly steps on manufacturing floors. The application tracks
            users progress, answers to questions, and utilizes the 3D models to
            provide more in depth training than any 2D work instruction can.
            <br />
            <br />
            This project implemented React, NodeJS, CosmosDB, S3, and TypeScript
          </p>
        </div>
      </PortPagePanel>
    </>
  );
}
