/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 Mars_1_6792.glb --transform 
Files: Mars_1_6792.glb [4.03MB] > Mars_1_6792-transformed.glb [132.13KB] (97%)
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function MarsModel(props) {
  // @ts-ignore
  const { nodes, materials } = useGLTF("/Mars_1_6792.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Cube008.geometry}
        material={materials["Default OBJ.005"]}
      />
    </group>
  );
}

useGLTF.preload("/Mars_1_6792.glb");
